import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import Fade from 'react-reveal/Fade';
import { withTranslation } from 'react-i18next'

import { GetMaterials, DeleteMaterial } from '../../../business/requests.js';

import LanguageSelector from '../../header/language_selector.component';

import './screen_materials.css';

class ScreenMaterials extends Component {

    constructor(props) {
        super(props);
    
        this.state = {
          materials: [],
        }

        this.onDelete = this.onDelete.bind(this)
    }

    componentDidMount() {
         // Retrieve all materials from API.
         GetMaterials().then(materials => {
            this.setState({
                materials: materials,
            })
        });
    }

    onDelete(materialName) {
        DeleteMaterial(materialName);
        window.location.href = '/material-management';
    }

    render() {
        // Retrieve i18n translation data.
        const { t } = this.props;
                
        return (
            <div>
                <div className='background-img'/>
                <Fade>
                    <div className='container vh-100'>
                        <div className='row padding-top-2'><LanguageSelector></LanguageSelector></div>
                        <div className='row padding-top-9'>
                            <h3>{t('title_materials')}</h3>
                        </div>
                        <div className='padding-top-1'></div>
                        <Button className='no-margin'id='btn-continue' variant="primary" href='/new-material'>{t('button_addmaterial')}</Button>
                        <div className='padding-top-2'>
                            {this.state.materials.map(material => (
                                <div className='card margin-below'>
                                    <div className='row '>
                                        <div className='col'>
                                            <label>{t('table_material')}</label>
                                            <input type="text" defaultValue={material.name} name="geometry-length" className="form-control min-width"/>
                                        </div>
                                        <div className='col'>
                                            <label>{t('table_residualvalue')}</label>
                                            <div className='prefix'>€</div>
                                            <div className='suffix suffix-very-long'>/ ton</div>
                                            <input type='number' step='1' defaultValue={material.value} onChange={console.log('edit')} className="form-control prefix-padding min-width"/>
                                        </div>
                                        <div className='col'>
                                            <label>{t('table_weight')}</label>
                                            <div className='suffix suffix-very-long'>{t('metric_kg')}</div>
                                            <input type='number' step='1' defaultValue={material.weight} onChange={console.log('edit')} className="form-control min-width"/>
                                        </div>
                                    </div>
                                    <Button className='btn-delete'id='btn-continue' onClick={() => this.onDelete(material.name)} variant="primary">{t('button_delete')}</Button>
                                </div>
                            ))}
                        </div>
                        <Button className='button-outlined' href='/' id='btn-back' variant="outline-primary">{t('button_back')}</Button>
                        <div className='padding-bottom-1'></div>
                    </div>                    
                </Fade>
            </div>
        )
    }
}

// Export with i18n translation.
export default withTranslation()(ScreenMaterials);
