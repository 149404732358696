import React, { Component } from 'react';
import { Accordion, Button, Table } from 'react-bootstrap';
import { withTranslation } from 'react-i18next'

import { formatMetricTon, formatEuros } from '../../../business/format.js';
import { exportPDF } from '../../../business/export.js';

import image from '../../../assets/images/icon_info.png';
import InfoModal from '../../modal/info_modal.component';
import LanguageSelector from '../../header/language_selector.component';

import './screen_results.css';

class ScreenResults extends Component {
  constructor(props) {
    super(props);
    
    this.export = this.export.bind(this);
    this.onReturn = this.onReturn.bind(this);

    this.state = {
      showInfoModal: false,
      showModalBMV: false
    }
  }

  onShowInfoModalBMV() {
    this.setState({ showModalBMV: true })
  }

  onShowInfoModal() {
    this.setState({ showInfoModal: true })
  }

  onCloseInfoModal() { 
    this.setState({
      showInfoModal: false,
      showModalBMV: false
    })
  }

  onReturn() {
    this.props.showComponent('Properties')
  }

  // Call export function from business, using table data, a table name and the address as filename.
  export(data) {
    // Locale data.
    const { t } = this.props;

    // Table data.
    let tableName = this.props.buildingInfo.street + ' ' + this.props.buildingInfo.number + ', ' + this.props.buildingInfo.postal + ' ' + this.props.buildingInfo.city;
    let fileName = this.props.buildingInfo.street + ' ' + this.props.buildingInfo.number;
    let residualValueArray = Array.from(this.props.residualValue)
    let totalResidualValue = residualValueArray[residualValueArray.length - 1].value;

    exportPDF(t, totalResidualValue, data, tableName, fileName);
  }

  render() {
    // Retrieve i18n translation data.
    const { t } = this.props;

    // Convert residual value data to array.
    let data = Array.from(this.props.residualValue);

    // Get the total residual value from the last entry in the data array.
    let totalResidualValue = data[data.length - 1].value;
    
    // Then remove that last entry so it doesn't get displayed when iterating through the array for table display.
    data.pop();
    
    return (
      <div>
        <div className='container vh-100'>
          <div className='row padding-top-2'><LanguageSelector></LanguageSelector></div>
          <div className='padding-top-2'></div>
          <div className='card'>
            <div className='row padding-top-1 text-center'>
              <h2>{this.props.buildingInfo.street} {this.props.buildingInfo.number}</h2>
              <h5>{this.props.buildingInfo.postal}, {this.props.buildingInfo.city}</h5>
            </div>
          </div>
          <div className='row padding-top-2'>
            <div className='card' id='card-margin'>
                <h4 id='label-residualValue' className='padding-top-1'>{t('label_bmv')} <b>€ {formatEuros(totalResidualValue, 10000)}</b>
                  <InfoModal show={this.state.showModalBMV} onCloseInfoModal={this.onCloseInfoModal.bind(this)} title={t('info_bmv_title')} text={t('info_bmv_body')} closeMessage={t('button_close')}></InfoModal>
                  <button onClick={this.onShowInfoModal.bind(this)} id='img-btn'><img alt='Toon meer informatie' className='info-img' src={image} height={25} width={25}></img></button>
                </h4>
            </div>
          </div>
          <div className='row padding-top-2'>
            <h6 className='text-white'>{t('table_descriptor')}</h6>
            {data.map(item => (
              <Accordion alwaysOpen>
                <Accordion.Item eventKey="0">
                <Accordion.Header> {t(item.name)} </Accordion.Header>
                <Accordion.Body>
                  <label className='color-blue'><b>Formule: </b>{item.calculation}</label>
                  <div className='padding-top-2'></div>
                  <Table responsive style={{ whiteSpace: 'nowrap' }}>
                    <thead>
                      <tr>
                        <th>{t('table_material')}</th>
                        <th>{t('table_mass')}</th>
                        <th>{t('table_residualvalue')}</th>
                        <th>{t('table_residualvalueRounded')}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{ item.material }</td>
                        <td>{ formatMetricTon(item.total) } {t('metric_ton')}</td>
                        <td>€ {formatEuros(item.value, 0)}</td>
                        <td>€ {formatEuros(item.value, 1000)}</td>
                      </tr>
                    </tbody>
                  </Table>
                </Accordion.Body>
              </Accordion.Item>       
              </Accordion>
            ))}
          </div>
          <div className='row padding-top-2'>
            <div className='col'>
              <Button className='button-outlined' variant="outline-primary" onClick={this.onReturn}>{t('button_back')}</Button>
              <Button className='button-export btn-longer' variant="primary" onClick={() => { this.export(data) } }>{t('button_export')}</Button>
            </div>
          </div>
          <div className='padding-bottom-2'></div>
        </div>
      </div>
    )
  }
}

// Export with i18n translation.
export default withTranslation()(ScreenResults);
