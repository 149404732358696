import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import './i18n'
import './App.css';

import Home from './presentation/home.component';
import ScreenMaterials from './presentation/screens/screen_materials/screen_materials.component'
import ScreenAddMaterial from './presentation/screens/screen_add_material/screen_add_material.component'

function App() {
  //console.log = console.warn = console.error = () => {};

  return (
    <div>
    <Router>
        <Routes>
          <Route path='/' exact element={<Home />} />
          <Route path='/material-management' exact element={<ScreenMaterials />} />
          <Route path='/new-material' exact element={<ScreenAddMaterial />} />
        </Routes>
    </Router>
    </div>
  );
}

export default App;
