import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import Fade from 'react-reveal/Fade';
import { withTranslation } from 'react-i18next'

import { AddMaterial } from '../../../business/requests.js';

import InfoModal from '../../modal/info_modal.component';
import LanguageSelector from '../../header/language_selector.component';

class ScreenAddMaterial extends Component {

    constructor(props) {
        super(props);
    
        this.state = {
            showModalError: false,
            name: '',
            weight: '',
            value: ''
        }
    }

    onAddMaterial() {
        if(this.state.name === ''  || this.state.weight === '' || this.state.value === '') {
            this.setState({ showModalError: true })
            return;
        }
        AddMaterial(this.state.name, this.state.value, this.state.weight).then(response => { });
        window.location.href = '/material-management';
    }
    
    onChangeName(e) {
        var name = e.target.value;

        this.setState({ name: name })
    }

    onChangeWeight(e) {
        var weight = e.target.value;

        this.setState({ weight: weight })
    }

    onChangeValue(e) {
        var value = e.target.value;

        this.setState({ value: value })
    }

    onCloseInfoModal() { 
        this.setState({
          showModalError: false
        })
      }

    render() {
        // Retrieve i18n translation data.
        const { t } = this.props;
        
        return (
            <div>
                <div className='background-img'/>
                <InfoModal show={this.state.showModalError} onCloseInfoModal={this.onCloseInfoModal.bind(this)} title={t('error_addmaterial_title')} text={t('error_addmaterial_body')} closeMessage={t('button_close')}></InfoModal>
                <Fade>
                    <div className='container vh-100'>
                        <div className='row padding-top-2'><LanguageSelector></LanguageSelector></div>
                        <div className='row padding-top-9'>
                            <h3>{t('title_add_material')}</h3>
                        </div>
                        <div className='padding-top-1'>
                            <div className='card margin-below'>
                                <div className='row padding-top-1 '>
                                    <div className='col'>
                                        <label>{t('table_material_name')}</label>
                                        <input type="text" placeholder="Dutch; English" value={this.state.materialName} onChange={this.onChangeName.bind(this)} className="form-control min-width"/>
                                    </div>
                                    <div className='col'>
                                        <label>{t('table_residualvalue')}</label>
                                        <div className='prefix'>€</div>
                                        <div className='suffix suffix-very-long'>/ ton</div>
                                        <input type='number' step='1' onChange={this.onChangeValue.bind(this)} className="form-control prefix-padding min-width"/>
                                    </div>
                                    <div className='col'>
                                        <label>{t('table_weight')}</label>
                                        <div className='suffix suffix-very-long'>{t('metric_kg')}</div>
                                        <input type='number' step='1' onChange={this.onChangeWeight.bind(this)} className="form-control min-width"/>
                                    </div>
                                </div>
                                <div className='padding-bottom-1'></div>
                            </div>
                        </div>
                        <div className='row'>
                          <div className='col'>
                            <Button className='button-outlined' href='/material-management' id='btn-back' variant="outline-primary">{t('button_cancel')}</Button>
                            <Button className='button-filled'id='btn-continue' onClick={this.onAddMaterial.bind(this)} variant="primary">{t('button_add')}</Button>
                          </div>
                        </div>
                        <div className='padding-bottom-2'></div>
                    </div>                          
                </Fade>
            </div>
        )
    }
}

// Export with i18n translation.
export default withTranslation()(ScreenAddMaterial);
